$breakpoints-up: (
  "ex-small": 42em,
  //576
  "mobile-large": 48em,
  //768
  "desktop-small": 64em,
  //1024
  "desktop-small-1": 80em,
  //1280
  "desktop-normal": 90em,
  //1440
  "desktop-normal-1": 110em,
  //1760
  "desktop-large": 116em,
  //1920
);

@mixin breakpoint($size) {
  @media (min-width: map-get($breakpoints-up, $size)) {
    @content;
  }
}
