@use "./../../scss/abstracts/index" as *;


.rodo-page {
  margin: 0 auto;
  margin-top: 3rem;

  width: 100%;
  max-width: 600px;

  display: flex;
  flex-direction: column;
  gap: 4rem;

  @include breakpoint(mobile-large) {
    max-width: 700px;
  }

  @include breakpoint(desktop-small) {
    max-width: 900px;
  }

  p{
    margin: 0;
    margin-bottom: 1rem;

    &:nth-child(1){
      margin-bottom: 1.5rem;
    }
  }
}