@use "../../../../scss/abstracts/variables" as *;

$menu-breakpoints: $global-breakpoints;

//example of breakpoints array
// $menu-breakpoints: (
//   "ex-small": 45em,
//   //576
//   "mobile-large": 48em,
//   //768
// );

@mixin breakpoint($size) {
  @media (min-width: map-get($menu-breakpoints, $size)) {
    @content;
  }
}
