@use "./../../../scss/abstracts/mixins" as *;
@use "./properties/variables" as *;

.gallery {
  width: 100%;
  max-width: $container-width;
  margin: 0 auto;
  padding: $container-padding-small;

  @include breakpoint(desktop-small-1) {
    padding: $container-padding-normal;
  }

  &__container {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  &__item {
    position: absolute;

    height: 100%;
    opacity: 0;
    -webkit-transition: opacity 0.8s ease-in-out;
    transition: opacity 0.8s ease-in-out;
    z-index: 80;

    &:last-child {
      position: relative;
    }

    img {
      max-width: none;
      display: block;
      position: relative;
      width: 100%;
    }
  }

  .current {
    opacity: 1;
  }
}
