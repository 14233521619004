@use "./../abstracts/index" as *;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap");

*,
*::after,
*::before {
  font-family: "Roboto", sans-serif;
}

p {
  text-align: justify;

  font-size: clamp(1.6rem, 1.53rem + 0.192vw, 2rem);
  letter-spacing: 0.02rem;
  line-height: 1.5;

  width: 100%;
  margin-bottom: clamp(1rem, 0.769rem + 0.641vw, 2rem);

  @include breakpoint(mobile-large) {
    line-height: 1.6;
  }

  @include breakpoint(desktop-normal) {
    line-height: 1.7;
  }

  a{
    font-weight: 600;
    color: #474747;
    //font-style: italic;
    text-decoration: none;
    transition: 0.3 all ease;

    &:hover{
      color: black;
    }
  }
}

h2 {
  font-size: clamp(1.8rem, 1.661rem + 0.384vw, 2.4rem);
  text-align: center;
  margin-bottom: 1rem;
}

// a {
//   color: $color-black;
//   text-decoration: none;
//   font-size: clamp(1.6rem, 1.53rem + 0.192vw, 2rem);
// }
