$color-white: #ffffff;
$color-white-1: #f5f5f5;
$color-white-2: #ebebeb;
$color-white-3: #e0e0e0;
$color-white-4: #d6d6d6;
$color-white-5: #cccccc;
$color-white-6: #c2c2c2;
$color-white-7: #b8b8b8;

$color-black: #000000;
$color-black-1: #0a0a0a;
$color-black-2: #141414;
$color-black-3: #1f1f1f;
$color-black-4: #292929;
$color-black-5: #333333;
$color-black-6: #3d3d3d;
$color-black-7: #474747;

$container-width: 1200px;
$container-padding-small: clamp(1.5rem, 1.384rem + 0.32vw, 2rem) 1rem;
$container-padding-normal: clamp(1.5rem, 1.384rem + 0.32vw, 2rem) 0rem;

$global-breakpoints: (
  "ex-small": 36em,
  //576
  "mobile-large": 48em,
  //768
  "desktop-small": 64em,
  //1024
  "desktop-small-1": 75em,
  //1200
  "desktop-small-2": 80em,
  //1280
  "desktop-normal": 90em,
  //1440
  "desktop-normal-1": 110em,
  //1760
  "desktop-large": 116em,
  //1920
);
