@use "../../../../scss/abstracts/variables" as v;

$media-background-color: v.$color-black-4;
$footer-background-color: v.$color-black-3;
$footer-font-color: v.$color-white;

$container-width: v.$container-width;
$container-padding-small: 1.5rem 1rem;
$container-padding-normal: 1.5rem 0rem;
$container-item-gap: 1.5rem;
