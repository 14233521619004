@use "../../../../scss/abstracts/variables" as v;

$animation-time: 10s;

$animation-element-count: 7;
$animation-element-check: $animation-element-count * 2;

$elements-gap-1: 4rem;
$element-width-1: 18rem;
$element-height-1: 18rem;

$slide-width-1: calc(
  ($element-width-1 * $animation-element-check) +
    (($animation-element-check - 2) * $elements-gap-1)
);

$slide-animation-1: calc(
  ($element-width-1 * $animation-element-count) +
    ($animation-element-count * $elements-gap-1)
);

$elements-gap-2: 5rem;
$element-width-2: 22rem;
$element-height-2: 22rem;

$slide-width-2: calc(
  ($element-width-2 * $animation-element-check) +
    (($animation-element-check - 2) * $elements-gap-2)
);

$slide-animation-2: calc(
  ($element-width-2 * $animation-element-count) +
    ($animation-element-count * $elements-gap-2)
);
