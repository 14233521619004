*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

main {
  // min-height: 50vh;
}

html {
  font-size: 62.5%;
}
