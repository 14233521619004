@use "./../scss/abstracts/index" as *;

.main {
  margin: 0 auto;
  width: 100%;
  max-width: $container-width;
  padding: $container-padding-small;

  @include breakpoint(desktop-small-1) {
    padding: $container-padding-normal;
  }
}
