@use "./properties/breakpoints" as *;
@use "./properties/variables" as *;


.media{
  background-color: $media-background-color;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container{
    width: 100%;
    max-width: $container-width;
 
    display: flex;
    flex-direction: column;
    gap: $container-padding-small;
    justify-content: center;
    align-items: center;
  
    padding: $container-padding-small;
  
    @include breakpoint(mobile-large) {
      flex-direction: row;
      justify-content: space-around;
    }
  
    @include breakpoint(desktop-small) {
      padding: 3rem 0rem;
    }
  }

  
  

  &__fbc{
    display: none;

    @include breakpoint(mobile-large) {
      display: block;
    }
  }


  img{
    max-width: 200px;
    display: block;

    @include breakpoint(mobile-large) {
      max-width: 240px;
    }

    @include breakpoint(desktop-small) {
      max-width: 280px;
    }

    @include breakpoint(desktop-normal) {
      max-width: 320px;
    }
  }
}

.footer {
  background-color: $footer-background-color;

  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    width: 100%;
    max-width: $container-width;
    padding: $container-padding-small;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $container-item-gap;

    color: $footer-font-color;
    text-align: center;

    @include breakpoint(desktop-small-2) {
      padding: $container-padding-normal;
    }

    @include breakpoint(mobile-large) {
      flex-direction: row;
      justify-content: space-between;
    }

    
  }

  &__company {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      font-size: 1.2rem;

      span {
        font-size: 0.8rem;
      }
    }

    @include breakpoint(mobile-large) {
      width: 30%;
      align-items: flex-start;
    }

    @include breakpoint(desktop-small) {
      div {
        font-size: 1.4rem;
  
        span {
          font-size: 1rem;
        }
      }
    }

    @include breakpoint(desktop-normal) {
      div {
        font-size: 1.6rem;
  
        span {
          font-size: 1.2rem;
        }
      }
    }
  }

  &__rodo {
    font-size: 1.2rem;

    a {
      font-style: italic;
      color: $footer-font-color;
      text-decoration: none;
      transition: 0.3s ease all;

      &:hover {
        color: rgb(167, 166, 166);
      }
    }

    @include breakpoint(mobile-large) {
      width: 30%;
    }

    @include breakpoint(desktop-small) {
      font-size: 1.4rem;
    }

    @include breakpoint(desktop-normal) {
      font-size: 1.6rem;
    }
  }

  &__design {
    font-size: 1.2rem;

    a {
      font-style: italic;
      color: $footer-font-color;
      text-decoration: none;
      transition: 0.3s ease all;

      &:hover {
        color: rgb(167, 166, 166);
      }
    }

    @include breakpoint(mobile-large) {
      width: 30%;
      text-align: right;
    }

    @include breakpoint(desktop-small) {
      font-size: 1.4rem;
    }

    @include breakpoint(desktop-normal) {
      font-size: 1.6rem;
    }
  }
}
