@use "./../../scss/abstracts/index" as *;

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5rem;

  &__about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include breakpoint(desktop-small) {
      flex-direction: row;
      justify-content: space-between;
    }

    &-text {
      width: 100%;
      max-width: 600px;

      @include breakpoint(mobile-large) {
        max-width: 700px;
      }

      @include breakpoint(desktop-small) {
        max-width: 55%;
      }
    }

    &-brands {
      overflow-x: hidden;
      width: 100%;
      max-width: 600px;

      @include breakpoint(mobile-large) {
        max-width: 700px;
      }

      @include breakpoint(desktop-small) {
        max-width: 40%;
      }
    }
  }

  &__offer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: clamp(1rem, 0.769rem + 0.641vw, 2rem);

    &-text {
      width: 100%;
      max-width: 600px;

      @include breakpoint(mobile-large) {
        max-width: 700px;
      }

      @include breakpoint(desktop-small) {
        max-width: 900px;
      }
    }

    &-box {
      width: 100%;
      max-width: 700px;
      margin-bottom: clamp(2.5rem, 2.153rem + 0.961vw, 4rem);
      margin-top: clamp(1rem, 0.769rem + 0.641vw, 2rem);
      display: flex;

      @include breakpoint(desktop-small-1) {
        max-width: 100%;
      }
    }

    &-link {
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: clamp(1.6rem, 1.53rem + 0.192vw, 2rem);
      width: clamp(14rem, 13.076rem + 2.564vw, 18rem);
      height: clamp(4rem, 3.538rem + 1.282vw, 6rem);

      color: $color-black;
      text-decoration: none;
      font-weight: 700;

      position: relative;

      &::before,
      &::after {
        content: "";
        transition: 0.3s ease all;
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: black;
      }

      &:hover {
        &::before,
        &::after {
          width: 100%;
          height: 100%;
          background-color: transparent;
        }
      }

      &::after {
        left: 0;
        top: 0;

        border-left: 2px solid black;
        border-top: 2px solid black;
      }

      &::before {
        content: "";
        right: 0;
        bottom: 0;

        border-right: 2px solid black;
        border-bottom: 2px solid black;
      }
    }
  }

  &__history {
    padding-top: clamp(2.5rem, 2.269rem + 0.641vw, 3.5rem);
    padding-bottom: clamp(1rem, 0.769rem + 0.641vw, 2rem);
    background-color: $color-white-1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-text {
      width: 100%;
      max-width: 600px;

      padding: 0rem $container-padding-small;

      @include breakpoint(mobile-large) {
        max-width: 700px;
      }

      @include breakpoint(desktop-small) {
        max-width: 900px;
      }

      @include breakpoint(desktop-small-1) {
        padding: 0rem $container-padding-normal;
      }
    }
  }
}
