@import "./../../../../scss/vendors/ClassicMenuBar/custom";

$menubar-main-color: #fff !default;

$container-width: 1200px !default;
$container-padding-small: 1.5rem 1rem !default;
$container-padding-normal: 1.5rem 0rem !default;

$image-max-width: clamp(10rem, 8.153rem + 5.128vw, 18rem) !default;

$button-size: 5rem !default;
$button-right: 1rem !default;
$button-top: clamp(1.8rem, 1.27rem + 1.47vw, 2.4rem) !default;
$button-radius: 50% !default;
$button-line-width: 2rem !default;
$button-line-height: 2px !default;
$button-line-separator: 0.7rem !default;
$button-line-color: #000 !default;
$button-color: #fff !default;
$button-shadow: 0px 0px 1px 2px #000 !default;
$background-plate-color: radial-gradient(
  rgba(#fff, 0.97),
  rgba(#000, 1)
) !default;

$menu-item-gap-small: 2rem !default;
$menu-item-gap-normal: 3rem !default;
$menu-item-font: clamp(1.8rem, 1.707rem + 0.256vw, 2.2rem) !default;
$menu-item-color: #000 !default;

$menu-item-active-font: clamp(2.4rem, 2.307rem + 0.256vw, 2.8rem) !default;
$menu-item-active-color: #000 !default;
