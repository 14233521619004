@use "./../../../scss/abstracts/mixins" as *;
@use "./properties/variables" as *;

.infinity-brand-carousel {
  width: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
  }

  &::before {
    left: -2%;
    right: 90%;
    background-image: linear-gradient(to right, #fff, transparent);
  }

  &::after {
    left: 90%;
    right: -2%;
    background-image: linear-gradient(to left, #fff, transparent);
  }

  &__slider {
    animation: scroll-1 $animation-time linear infinite;
    display: flex;
    gap: $elements-gap-1;
    width: $slide-width-1;

    @include breakpoint(desktop-normal) {
      gap: $elements-gap-2;
      width: $slide-width-2;
      animation: scroll-2 $animation-time linear infinite;
    }
  }

  &__slide {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: $element-width-1;

      @include breakpoint(desktop-normal) {
        width: $element-width-2;
      }
    }
  }
}

@keyframes scroll-1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-$slide-animation-1);
  }
}

@keyframes scroll-2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-$slide-animation-2);
  }
}
