@use "./../../../scss/abstracts/index" as *;

.offer-gallery {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;

  &__item {
    width: 280px;
    padding: 0.4rem;

    background-color: white;
    border: 1px solid black;

    &-photo {
      position: relative;

      width: 100%;
      height: 180px;

      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-box {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 1rem;

      font-size: 1.6rem;
      font-weight: 700;

      height: 50px;

      color: #efefef;
      background-color: $color-black;

      a {
        margin-left: 2rem;
        padding: 0.5rem 1rem;

        font-size: 1.4rem;
        font-weight: 700;
        text-decoration: none;

        color: black;
        background-color: white;

        transition: 0 s ease all;

        &:hover {
          box-shadow: 0px 0px 4px 1px rgb(151, 151, 151);
        }
      }
    }
  }
}
