@use "./../../scss/abstracts/index" as *;

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5rem;

  &__ctc{
    width: 100%;
  }

  &__description{
    margin: 0 auto;

    width: 100%;
    max-width: 600px;

    @include breakpoint(mobile-large) {
      max-width: 700px;
    }

    @include breakpoint(desktop-small) {
      max-width: 900px;
    }
  }

  &__info{
    display: flex;
    flex-direction: column;


    img{
      width: 4rem;
      height: 4rem;
      border-radius: 1rem;
    }

    p{
      padding: 0;
      margin: 0;
      width: auto;

      a{
        color: black;
        transition: 0.3s ease all;
        font-weight: 400;

        &:hover{
          color: #666;
        }
      }
    }

    &-container{
      margin: 0 auto;
      width: 100%;
      max-width: 600px;

      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      gap: 2rem;


      @include breakpoint(mobile-large) {
        max-width: 700px;
        flex-direction: row;
      }
  
      @include breakpoint(desktop-small) {
        max-width: 900px;
      }
 
    }

    &-single{
      margin-top: 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;

      width: 220px;

      @include breakpoint(mobile-large) {
        justify-content: center;
        width: auto;
      }
      
    }

    &-open{
      margin: 0 auto;
      width: 100%;
      max-width: 600px;

      margin-top: 2.5rem;
      
      p{
        text-align: center;
        font-size: 1.2rem;
      }

      @include breakpoint(ex-small) {
        p{
          font-size: 1.6rem;
        }
      }
    }
  }



  &__map{
    margin: 0 auto;
    margin-bottom: 0.5rem;

    width: 100%;
    max-width: 600px;

    iframe{
      width: 100%;
    }

    @include breakpoint(mobile-large) {
      max-width: 700px;
    }

    @include breakpoint(desktop-small) {
      max-width: 900px;
    }
  }
}
