@use "./../../scss/abstracts/index" as *;


.offer-page {
  margin: 0 auto;
  margin-top: 3rem;

  width: 100%;
  max-width: 600px;

  display: flex;
  flex-direction: column;
  gap: 4rem;

  @include breakpoint(mobile-large) {
    max-width: 700px;
  }

  @include breakpoint(desktop-small) {
    max-width: 900px;
  }

  p{
    margin: 0;
    margin-bottom: 1rem;

    &:nth-child(1){
      margin-bottom: 1.5rem;
    }
  }

  &__sell{
    display: grid;
    grid-template-columns: 1f;
    justify-items: center;
    align-items: center;
    gap: 1rem;

    @include breakpoint(desktop-small) {
      grid-template-columns: repeat(2, 1fr);
    }

    &-item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-width: 360px;
      height: 60px;
      border: 1px solid black;
      align-self: flex-start;

      @include breakpoint(ex-small) {
        min-width: 420px;
      }

      @include breakpoint(desktop-small-1) {
        height: 70px;
        min-width: 460px;
      }

      div{
        display: flex;
        flex-direction: column;
        width: 90%;
        padding-left: 1rem;

        p{
          width: 100%;
          margin: 0;

          &:nth-child(1){
            margin-bottom: 0rem;
          }
  
          &:nth-child(2){
            font-size: 1.2rem;

            @include breakpoint(ex-small) {
              font-size: 1.3rem;
            }

            @include breakpoint(desktop-small-1) {
              font-size: 1.4rem;
            }
          }
        }
      }

      span{
        width:28%;
        font-size: 1.4rem;
        text-align: center;

        padding: 1rem ;
        font-style: italic;
        font-weight: 700;
        margin: 0;

        border-left: 1px solid black;
        

        @include breakpoint(ex-small) {
          font-size: 1.5rem;
        }

        @include breakpoint(desktop-small-1) {
          font-size: 1.6rem;
        }
      }
    }

    &-item--large{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-width: 360px;
      height: 90px;
      border: 1px solid black;
      align-self: flex-start;

      @include breakpoint(ex-small) {
        min-width: 420px;
      }

      @include breakpoint(desktop-small-1) {
        height: 100px;
        min-width: 460px;
      }

      div{
        display: flex;
        flex-direction: column;
        width: 90%;
        padding-left: 1rem;

        p{
          width: 100%;
          margin: 0;

          &:nth-child(1){
            margin-bottom: 0rem;
          }
  
          &:nth-child(2),&:nth-child(3){
            font-size: 1.2rem;

            @include breakpoint(ex-small) {
              font-size: 1.3rem;
            }

            @include breakpoint(desktop-small-1) {
              font-size: 1.4rem;
            }
          }
        }
      }

      span{
        width:28%;
        font-size: 1.4rem;
        text-align: center;

        padding: 2rem 1rem ;
        font-style: italic;
        font-weight: 700;
        margin: 0;

        border-left: 1px solid black;
        

        @include breakpoint(ex-small) {
          font-size: 1.5rem;
        }

        @include breakpoint(desktop-small-1) {
          font-size: 1.6rem;
        }
      }
    }
  }

}