@use "./../../../scss/abstracts/mixins" as *;
@use "./properties/variables" as *;

.menubar {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  background-color: $menubar-main-color;

  &__container {
    display: flex;
    justify-content: space-between;

    width: 100%;
    max-width: $container-width;
    padding: $container-padding-small;

    background-color: $menubar-main-color;

    @include breakpoint(desktop-small-1) {
      padding: $container-padding-normal;
    }
  }

  &__logo {
    width: 100%;
    max-width: $image-max-width;

    img {
      width: 100%;
      display: block;
    }
  }

  &__button {
    position: fixed;
    z-index: 2000;

    height: $button-size;
    width: $button-size;
    top: $button-top;
    right: $button-right;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $button-color;
    border-radius: $button-radius;
    box-shadow: $button-shadow;
    cursor: pointer;

    span {
      position: relative;

      &,
      &::before,
      &::after {
        width: $button-line-width;
        height: $button-line-height;
        background-color: $button-line-color;
        display: inline-block;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        transition: all 0.2s;
      }

      &::before {
        top: -$button-line-separator;
      }

      &::after {
        top: $button-line-separator;
      }
    }

    &--active {
      span {
        background-color: transparent;

        &::before {
          top: 0rem;
          transform: rotate(135deg);
        }

        &::after {
          top: 0rem;
          transform: rotate(-135deg);
        }
      }
    }

    @include breakpoint(mobile-large) {
      display: none;
    }
  }

  &__background {
    height: $button-size;
    width: $button-size;
    top: $button-top;
    right: $button-right;
    border-radius: $button-radius;

    position: fixed;

    background-image: $background-plate-color;
    z-index: 1000;
    transition: transform 0.6s cubic-bezier(0.86, 0, 0.07, 1);

    &--active {
      transform: scale(80);
    }

    @include breakpoint(mobile-large) {
      display: none;
    }
  }

  &__menu {
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 1500;

    opacity: 0;
    width: 0;
    transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    @include breakpoint(mobile-large) {
      position: relative;
      opacity: 1;
      width: auto;
      height: auto;
      left: 0%;
    }

    &--active {
      opacity: 1;
      width: 100%;
      left: 0%;
    }

    &-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: $menu-item-gap-small;

      width: 100%;
      height: 100%;

      @include breakpoint(mobile-large) {
        flex-direction: row;
        align-items: center;
        width: auto;
      }

      @include breakpoint(desktop-large) {
        gap: $menu-item-gap-normal;
      }
    }

    &-item {
      &:link,
      &:visited {
        font-size: $menu-item-font;
        text-transform: uppercase;

        color: $menu-item-color;
        text-decoration: none;

        background-size: 220%;
        transition: all 0.4s;

        height: $menu-item-font;
      }

      &:hover,
      &:active {
        color: $menu-item-active-color;
      }

      &--active {
        &:link,
        &:visited {
          color: $menu-item-active-color;
          font-size: $menu-item-active-font;
          height: $menu-item-active-font;
        }
      }
    }
  }
}
